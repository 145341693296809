import React from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColumns,
  GridRowId,
} from '@material-ui/data-grid';

import { TableContainer } from './styles';

import { useQuitacao } from '../../QuitacaoContext';
import moment from 'moment';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

export const TableTitulos: React.FC = () => {
  const { finalizadoras, parcelas, onChangeSelectedParcelas } = useQuitacao();

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_loja',
      headerName: 'Loja',
      width: 100,
      disableColumnMenu: true,
      align: 'right',
    },
    {
      field: 'num_docto',
      headerName: 'Docto',
      width: 120,
    },
    {
      field: 'nome_pessoa',
      headerName: 'Parceiro',
      flex: 1,
      minWidth: 220,
    },
    {
      field: 'cod_finalizadora',
      headerName: 'Finalizadora',
      minWidth: 220,
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const { row } = params;
        return (
          <>
            {
              finalizadoras.find(
                (finalizadora) => row.cod_finalizadora === finalizadora.value,
              )?.label
            }
          </>
        );
      },
    },
    {
      field: 'val_parcela',
      headerName: 'Valor',
      width: 110,
      align: 'right',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div>
            {row.val_parcela
              .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
              .replace(/^(\D+)/, '')}
          </div>
        );
      },
    },
    {
      field: 'dta_entrada',
      headerName: 'Entrada',
      width: 160,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            {moment(
              createDateWithoutTimezone(row.dta_entrada),
              'YYYY-MM-DD',
            ).format('DD/MM/YYYY')}
          </>
        );
      },
    },
    {
      field: 'dta_vencimento',
      headerName: 'Vencimento',
      width: 160,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            {moment(
              createDateWithoutTimezone(row.dta_vencimento),
              'YYYY-MM-DD',
            ).format('DD/MM/YYYY')}
          </>
        );
      },
    },
  ];

  const onSelectedParcela = (selected: GridRowId[]) => {
    const selectedIds = new Set(selected);
    const selectedParcelas = parcelas.filter((parcela) => {
      return selectedIds.has(parcela.id);
    });
    onChangeSelectedParcelas(selectedParcelas);
  };

  return (
    <TableContainer>
      <DataGrid
        rowsPerPageOptions={[5]}
        rows={parcelas}
        columns={columns}
        pageSize={5}
        checkboxSelection
        onSelectionModelChange={(selected: GridRowId[]) =>
          onSelectedParcela(selected)
        }
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} linhas selecionadas`
              : `${count.toLocaleString()} linha selecionada`,
        }}
      />
    </TableContainer>
  );
};
