import styled from 'styled-components';

export const Container = styled.div`
  .containerRow {
    .containerPeriodo {
      width: 20% !important;
      button {
        min-width: 90px;
      }
    }
    .containerTipo {
      width: 20% !important;
      button {
        min-width: 90px;
      }
    }

    .colContainer {
      display: flex;
      align-items: flex-end;
      width: 20%;
      margin-left: -0.75rem;
    }
    .buttonContainer {
      display: flex;
      gap: 0.3125rem;

      .button {
        min-width: 50px !important;
        color: #fff;
      }
    }

    @media screen and (min-width: 1500px) {
      .colContainer {
        width: 25%;
      }
    }

    @media screen and (min-width: 1400px) {
      .colContainer {
        width: 25%;
      }
    }

    @media screen and (max-width: 768px) {
      .colContainer {
        margin-top: 15px;
        margin-left: 4px;
      }
    }

    @media screen and (max-width: 1200px) {
      .colDataContainer {
        margin-left: -10px;
        width: 16.6666666667%;
      }
      .colContainer {
        width: 30%;
      }

      .containerPeriodo {
        width: 18% !important;
        button {
          min-width: 90px !important;
        }
      }
      .containerTipo {
        width: 18% !important;
        button {
          min-width: 90px !important;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      .colDataContainer {
        margin-left: -5px;
        width: 16.6666666667%;
      }
      .colContainer {
        width: 33%;
      }

      .containerPeriodo {
        width: 17% !important;
        button {
          min-width: 50px !important;
        }
      }
      .containerTipo {
        width: 17% !important;
        button {
          min-width: 50px !important;
        }
      }
    }

    @media screen and (max-width: 991px) {
      .colDataContainer {
        width: 27%;
        margin-left: 3px;
      }
      .colContainer {
        width: 46%;
      }
      .containerPeriodo {
        width: 45% !important;
        button {
          min-width: 160px !important;
        }
      }
      .containerTipo {
        width: 45% !important;
        button {
          min-width: 130px !important;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .teste {
        button {
          min-width: 130px !important;
        }
      }
      .colDataContainer {
        width: 45%;
        margin-left: 3px;
      }
      .colContainer {
        width: 100%;
      }
      .containerPeriodo {
        width: 100% !important;
        button {
          min-width: 50% !important;
        }
      }
      .containerTipo {
        width: 100% !important;
        button {
          min-width: 50% !important;
        }
      }
    }
  }
`;

export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  .btn-group {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    min-width: 100px;
    padding-top: 9px;
    height: 40px;

    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  .btn-group-active {
    background-color: #57069e;
    color: #ecedf0;
  }
`;

export const ContainerDate = styled.div`
  .alert-class {
    border-bottom: 2px solid red !important;
    border-radius: 5px !important;
  }
`;

export const Title = styled.p`
  margin-top: 10px;
  margin-left: 0px;
  position: absolute;
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
  font-weight: 600;
  display: inline-block;
  margin-bottom: 4px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;

export const InpuTypeDate = styled.input`
  height: 40px;
  margin-top: 30px;
  background-color: #fafbfc;
  border: 2px solid;
  border-color: #dfe1e6;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 20px;
  color: inherit;
  cursor: inherit;
  font-size: 14px;
  min-width: 0;
  /* outline: none; */
  width: 100%;
  line-height: 1.4285714285714286;
  &:disabled {
    cursor: not-allowed !important;
    color: gray;
  }
`;
