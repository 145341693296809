import React, { ChangeEvent } from 'react';

import { Row, Col } from 'react-bootstrap';

import { useQuitacao } from '../../QuitacaoContext';

import { Select } from '../../protocols';
import ContaBanco from '~/components/ContaBanco';

import { Text, Title } from './styles';
import Separator from '~/components/Separator';

import { FormActions } from './FormActions';
import { InpuTypeDate } from '~/components/Pessoa/styles';
import moment from 'moment';

export const FormQuitacao: React.FC = () => {
  const {
    finalizadoras,
    selectedParcelas,
    disabledFields,
    formQuitacao,
    onChangeFormQuitacao,
    formFiltros,
    total,
    contas,
    init,
  } = useQuitacao();

  return (
    <>
      <Row className="d-flex items-start">
        <Col md={9} sm={12}>
          <Separator labelText="Dados da Quitação" />
        </Col>
        <Col md={3} sm={12} style={{ marginTop: '0.5rem' }}>
          <Text
            style={{
              fontSize: '1.25rem',
            }}
          >
            Selecionados: R${' '}
            {total.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
          </Text>
        </Col>
      </Row>

      <Row className="d-flex content-center">
        <Col sm={12} md={3}>
          <ContaBanco
            value={
              contas.find((item: Select) => {
                return item.value === formQuitacao.cod_cc.value.value;
              }) ?? {
                value: undefined,
                label: 'Informe a Conta',
              }
            }
            isRequired={formQuitacao.cod_cc.isRequired}
            setInvalid={formQuitacao.cod_cc.isInvalid}
            iniInicializado={init}
            onChange={(value: Select) => {
              onChangeFormQuitacao({
                ...formQuitacao,
                cod_cc: {
                  ...formQuitacao.cod_cc,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
            isDisabled={!disabledFields}
            loja={formFiltros.cod_loja.value}
          />
        </Col>
        <Col sm={12} md={3} lg={2}>
          <Title
            style={{
              marginTop: '10px',
              marginLeft: '0px',
              position: 'absolute',
            }}
          >
            Quitar em
          </Title>

          <InpuTypeDate
            style={{
              borderBottom:
                formQuitacao.dta_quitacao.isInvalid && init
                  ? '2px solid #cf1919'
                  : '',
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const isInvalid = !moment(e.target.value).isValid();
              onChangeFormQuitacao({
                ...formQuitacao,
                dta_quitacao: {
                  ...formQuitacao.dta_quitacao,
                  value: e.target.value,
                  isInvalid,
                },
              });
            }}
            disabled={!disabledFields}
            value={formQuitacao.dta_quitacao.value}
            type="date"
          />
        </Col>
        <Col
          sm={12}
          md={6}
          lg={7}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <FormActions />
        </Col>
      </Row>
    </>
  );
};
